<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "NumberSolutions"
}
</script>

<style scoped>

</style>